// table:hover {
//     // border-spacing: 0;
//     // border-collapse: collapse;
//     overflow: hidden;
//     // background-color: tomato;
//  }
 
// // .cell_hover {

//     .cell_hover:hover {
//         background-color: rgba(255, 255, 0, 0.5) !important;
//     }
    
   
    
//     td:hover::after,tr:hover::after { 
//        background-color: rgba(255, 255, 0, 0.5)  !important;
//        content: '\00a0'  !important;  
//        height: 10000px  !important;    
//        left: 0  !important;
//        position: absolute  !important;  
//        top: -5000px  !important;
//        width: 100%  !important;   
//     }


// input[type="radio"] {
//     /* ...existing styles */
//     display: grid;
//     place-content: center;
//   }


input[type=radio] {
	appearance: none;
  background-color: #fff;
  margin: 0;
	font: inherit;
  color: #00C7B1;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
	display: grid;
  place-content: center;
	&::before {
		content: "";
		width: 0.65em;
		height: 0.65em;
		border-radius: 50%;
		transform: scale(0);
		transition: 120ms transform ease-in-out;
		box-shadow: inset 1em 1em #00C7B1;
		background-color: CanvasText;
		transform-origin: top bottom;
	}
	&:checked::before {
		transform: scale(1);
	}
}