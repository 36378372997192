@mixin scrollbars(
  $size,
  $foreground-color,
  $hover-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $hover-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

.bounty_teeth_controls {
  position: absolute;
  top: 70px;
  z-index: 99;
  right: 278px;
  height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: visible;
  @include scrollbars(5px, #313131, #2b2f2e, rgba(100, 100, 100, 0.2));
}
.bounty_teeth_controls_1 {
  filter: drop-shadow(0px 3.6137566566467285px 3.6137566566467285px #00000040);
  position: absolute;
  top: 70px;
  z-index: 99;
  right: 10px;
  height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: visible;
  @include scrollbars(5px, #313131, #2b2f2e, rgba(100, 100, 100, 0.2));
}

.bounty_urr {
  position: sticky;
  position: -webkit-sticky;
  top: 0; /* required */
  z-index: 99999;
  filter: drop-shadow(0px 3.6137566566467285px 3.6137566566467285px #00000040);
  display: flex;
  height: 48px;
  background-color: #ffffff;
  padding: 12px 24px 12px 24px;
  border-radius: 4px;
  justify-content: space-between;
  div {
    cursor: pointer;
  }
}

.bounty_undo:active {
  .bounty_undo_svg {
    fill: #00b189;
  }
}
.bounty_redo:active {
  .bounty_redo_svg {
    fill: #00b189;
  }
}
.bounty_reset:active {
  .bounty_rest_svg {
    fill: #00b189;
  }
}

.bounty_tooth_position {
  display: flex;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  flex-direction: column;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;
  z-index: 9999;
  padding: 10px;
  margin-top: 10px;
  filter: drop-shadow(0px 3.6137566566467285px 3.6137566566467285px #00000040);
}

.bounty_tooth_position_div_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  padding: 8px 4px;
}
.bounty_tooth_position_div_2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
  gap: 10px;
  padding: 8px 4px;
}

.arrow {
  content: "";
  z-index: 1000;
  width: 10px;
  height: 10px;
  border: 2px solid #333;
  border-top: 0px solid #f2f2f2;
  border-right: 0px solid #f2f2f2;
  transform: rotate(135deg);
  transition: 0.5s;
  //pointer-events: none;
}
.arrow_active {
  transform: rotate(-45deg);
}

.bounty_divider {
  content: "";
  background-color: #ebebeb;
  height: 1px;
  width: 90%;
  margin: auto 0;
  display: grid;
}

.bounty_tooth_position_div_2_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //cursor: pointer;
}
.bounty_tooth_position_div_2_2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

#bounty_tooth_select {
  position: relative;
  z-index: 999;
}

//

.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 81px;
  height: 30px;
}

.dropdown-button {
  //cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 81px;
  height: 30px;
  padding: 10px 8px 10px 8px;
}

.dropdown-button.open {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.arrow {
  margin-left: 8px;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #f2f2f2;
  width: 100%;
  list-style: none;
  padding: 0;
  height: 100px;
  overflow-y: auto;
  @include scrollbars(5px, #313131, #2b2f2e, rgba(100, 100, 100, 0.2));
}

.dropdown-tooth_position {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #f2f2f2;
  width: 100%;
  list-style: none;
  padding: 0;
}

.dropdown-options li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-options li:hover {
  background-color: rgb(197, 197, 197);
}

.tooth_position_value {
  background-color: #f2f2f2;
  width: 75px;
  height: 30px;
  border-radius: 4px;
  padding: 4px 10px;
}

.bounty_tooltip_content_tooth_movement {
  display: none;
  box-sizing: border-box;
  color: #ffffff;
  background: black;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 8px;
  width: max-content;
  margin: 0 auto;
  height: 44px;
}

.bounty_tooltip_content_tooth_movement[data-show="true"] {
  display: block;
}

.bounty_tooltip_content_tooth_movement:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  left: 100%;
  bottom: 45%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: black;
}

.bounty_delete_att {
  background-color: #f2f2f2;
  width: 173px;
  height: 40px;
  margin: auto;
  cursor: pointer;
}

textarea {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none !important;
}

.bounty_minus:active {
  svg {
    .rect {
      fill: #00b189;
    }
    .path {
      fill: white;
    }
  }
}
.bounty_plus:active {
  svg {
    .rect {
      fill: #00b189;
    }
    .path {
      fill: white;
    }
  }
}

.bounty_beta {
  width: 79px;
  height: 33px;
  position: absolute;
  padding: 2px 4px 2px 4px;
  border-radius: 33px;
  background-color: #fff1a8;
  color: #424242;
  bottom: 10px;
  z-index: 99;
  left: 16px;
  display: grid;
  align-items: center;
}

.selected_att {
  border: 2px solid #009ace;
  border-radius: 4px;
}
.attachment_log {
  svg {
    width: 34px;
    height: 34px;
    .bounty_att_bg_color {
        fill: #fff
    }
  }
}

.bounty_controls_disabled {
  cursor: not-allowed !important;
  text-align: center;
  svg {
    opacity: 0.7;
  }
  div {
    opacity: 0.7;
  }
  &:hover {
    .bounty_tooltip_content {
      transition-delay: 0s;
      visibility: visible;
      font-weight: 500;
      opacity: 1 !important;
    }
  }
}

.add_ipr_spacing {
  position: absolute;
  cursor: pointer;
  top: 35px;
  height: 30px;
  width: 23px;
  border-radius: 4px;

  filter: drop-shadow(0px 3.6137566566467285px 3.6137566566467285px #00000040);
}

.selected_tooth {
  svg {
    .tooth_svg {
      fill: #898787;
    }
  }
}

.adjust_spacing {
  z-index: 99;
  position: absolute;
  background-color: white;
  width: 127px;
  height: 32px;
  cursor: pointer;
  display: grid;
  top: 125px;
  left: 20%;
  align-items: center;
  border-radius: 4px;
  filter: drop-shadow(0px 3.6137566566467285px 3.6137566566467285px #00000040);
}
.adjust_IPR {
  z-index: 99;
  position: absolute;
  background-color: white;
  width: 127px;
  left: 20%;
  height: 32px;
  cursor: pointer;
  display: grid;
  top: 165px;
  align-items: center;
  border-radius: 4px;
  filter: drop-shadow(0px 3.6137566566467285px 3.6137566566467285px #00000040);
}
