.autosave {
    position: absolute;
    z-index: 99;
    display: flex;
    left: 10px;
    border-radius: 4px;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
