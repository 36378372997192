/* This css is for normalizing styles. You can skip this. */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.new {
  padding: 50px;
}

.form-group {
  display: block;
  margin-bottom: 4px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
  &:disabled {
    cursor: default;
  }
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #727272;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 5px;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 5px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.form-group input:checked + label:before {
  background-color: #00c7b1;
  border: 2px solid #00c7b1;
}

.form-group input:checked:disabled + label:before {
  background-color: #818181;
  border: 2px solid #818181;
  cursor: default;
}
.form-group input:disabled + label:before {
  background-color: #ffff;
  border: 2px solid #da0606;
  cursor: default;
}
.form-group input:disabled + label {
  cursor: default;
  color: #da0606;
}
.form-group input:checked:disabled + label {
  cursor: default;
  color: #2e2e2f;
}
