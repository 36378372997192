.tooth_title {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 4px;
  color: #3d3935;
}
.movement_text {
  font-size: 12px; // i change it from 14 to 12 to match title size
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #2e2e2f;
}
.level_2_title {
  //styleName: Heading up a group of list items.;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #7d7f8e;
  margin-bottom: 8px;
}
.modification_contanier {
  display: flex;
  width: 280px;
  padding: 8px 10px 8px 10px;
  border-radius: 4px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 3.6137566566467285px 3.6137566566467285px 0px #00000040;
  overflow: auto;
  flex-direction: column;
}
.level_1_title {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: left;
}
.header_grid {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.close_modification {
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: #696969;
  cursor: pointer;
}
.doctor_modification_card {
  position: absolute;
  display: flex;
  width: 229px;
  height: 49px;
  bottom: 60px;
  right: 10px;
  padding: 12px;
  border-radius: 4px;
  gap: 10px;
  background: #fff;
  box-shadow: 0px 3.6137566566467285px 3.6137566566467285px 0px #00000040;
  cursor: pointer;
}
.modification_numbers {
  width: 18px;
  height: 18px;
  padding: 3.49px 1.61px 3.49px 1.61px;
  border-radius: 36.61px;
  border: 2px;
  gap: 8.72px;
  color: #fff;
  background-color: #da0606;
  top: -10px;
  right: -8px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}

.timeline_container {
  width: 100%;
  // padding: 50px 0;
  // margin: 50px auto;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.timeline_container:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 2%;
  margin-left: -1px;
  width: 1px;
  height: 100%;
  background: #ccd1d9;
  z-index: 1;
}

.timeline-block {
  display: flex;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  // border: 2px solid #d9d9d9;
  background: #d9d9d9;
  margin-top: 6px;
  z-index: 9999;
}

.timeline-content {
  width: 95%;
  padding: 0 8px;
  color: #666;
}
.movements_list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.compare_card {
  padding: 10px 16px;
  display: flex;
  height: 40px;
}
.compare_container {
  box-shadow: 0px 3.6137566566467285px 3.6137566566467285px 0px #00000040;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.actions_container {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  justify-content: flex-end;
}
.cancle_btn {
  background: #fff;
  color: #3d3935;
  padding: 8px 8px;
  border: 1px solid #3d3935;
  border-radius: 25px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
}
.save_btn {
  background: #009ace;
  color: #fff;

  width: 76px;
  height: 37px;
  padding: 12px 20px 12px 20px;
  border-radius: 3px;
  gap: 8px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
}
.version {
  border-top: 1px solid #d8d7d7;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
}
