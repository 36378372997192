$ts_primary: #00b189;
$ts_secondary: #00b189;
$w:3px; /* Thiclness of the line */
$n:8;
.bounty_active_bitejump {
  
  background:
  repeating-linear-gradient(-45deg,
   #0000 0 calc((100% - 5*5px)/12),
   #0000004D  0 calc((100% - 5*5px)/12 + 5px));

}

#bounty_stepper {
  background-color: white;
  height: 55px;
  display: grid;
  grid-template-columns: auto auto minmax(0, 1fr);
  grid-row: 3;
  align-items: center;
  border-top: 2px #c8c8ca solid;
  z-index: 99;
  // position: absolute;
  // bottom: 0;
  width: 100vw;
}
#bounty_stepper_mobile {
  background-color: white;
  height: 55px;
  display: flex;
  border-bottom: 1px solid #c2c2c2;
  justify-content: space-between;
  align-items: center;
  z-index: 11;
  //border-radius: 5px 5px 0 0;
  //position: absolute;
  //bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 100%;
  grid-row: 2;
  //padding-bottom: 10px;

  //bottom: calc(60px + env(safe-area-inset-bottom))
}
#bounty_player {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 20px;
  z-index: 11;
  svg {
    cursor: pointer;
  }
}
#bounty_player_mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  height: 55px;
  svg {
    cursor: pointer;
  }
}

.bounty_play_pause {
  svg {
    cursor: pointer;
    .bounty_play {
      fill: var(--ts_primary, $ts_primary);
    }
    .bounty_pause {
      fill: var(--ts_primary, $ts_primary);
    }
  }
}

#bounty_player_large_steps {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  background-color: white;
  width: 210px;
  border-radius: 5px 5px 0 0;
  position: absolute;
  bottom: 55px;
  left: calc(50% - 105px);
  padding: 5px 30px;
  // border-bottom: 1px #C8C8CA solid;
  svg {
    cursor: pointer;
  }
}
// .bounty_step_div {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     height: 60px;
//     width: 100%;
//     font-size: 12px;
//     justify-content: space-evenly;
//     @media (max-width: 810) {
//       display: none;
//     }
//   }
.bounty_empty_step {
  height: 5px;
  content: "";
}

.bounty_active_step_mobile {
  background-color: var(--ts_secondary, $ts_secondary);
  //margin: auto 0;
  position: relative;
  width: 10px;
  min-width: 10px;
  margin: 0px 4px;
  height: 50px;
  top: 4px;
  .bounty_step {
    background-color: var(--ts_secondary, $ts_secondary);

    margin: 0 auto;
    height: 5px;
  }
  .bounty_empty_step {
    background-color: #006c6000;
    margin: 0 auto;
    height: 5px;
  }
  .bounty_firstStep {
    background-color: var(--ts_secondary, $ts_secondary);
    width: 80%;
    margin: 0 auto;
    height: 5px;
  }
  .bounty_overCorrectionStepsNumber {
    background-color: red;

    margin: 0 auto;
    height: 5px;
  }
  .bounty_passiveAligners {
    background-color: lightgrey;
    width: 80%;
    margin: 0 auto;
    height: 5px;
  }
}

.bounty_step_tooltip::after {
  content: "";
  position: absolute;
  z-index: 12;
  top: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 10.2px;
  border-radius: 4px;
  padding: 8px 4px;
  left: 25%;
  right: 25%;
  margin: 0 auto;
  text-align: center;
  z-index: 9;
}
.bounty_step_tooltip_mobile::before {
  content: "";
  position: absolute;
  z-index: 12;
  top: 0px;
  height: 55px;
  width: 10px;
  cursor: pointer;
  text-align: center;
  background-color: var(--ts_secondary, $ts_secondary);
}
.bounty_step_tooltip_mobile::after {
  content: "";
  position: absolute;
  z-index: 12;
  top: 0px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 10.2px;
  border-radius: 50%;
  padding: 14px;
  text-align: center;
  background-color: var(--ts_secondary, $ts_secondary);
}
.bounty_step_tooltip_mobile_active::before {
  content: "";
  position: absolute;
  z-index: 12;
  top: 0px;
  height: 55px;
  width: 10px;
  cursor: pointer;
  text-align: center;
  background-color: var(--ts_primary, $ts_primary);
}
.bounty_step_tooltip_mobile_active::after {
  content: "";
  position: absolute;
  z-index: 12;
  top: -16px;
  cursor: pointer;
  border-radius: 50%;
  padding: 22px;
  text-align: center;
  background-color: var(--ts_primary, $ts_primary);
}

.bounty_active_ipr {
  fill: var(--ts_primary, $ts_primary) !important;
}
.bounty_small_steps {
  font-size: 1em;
}
.bounty_medium_steps {
  font-size: 1em;
}
.bounty_large_steps {
  font-size: 1em;
}
.bounty_current_step {
  position: absolute;
  z-index: 12;
  top: 8px;
  font-size: 14px;
  line-height: 10.2px;
  font-weight: 600;
  color: #555555;
  border-radius: 4px;
  padding: 4px;
  width: 18px;
  height: 10px;
  text-align: center;
}
.bounty_label_upper {
  width: 100%;
  color: #2e2e2f;
  z-index: 22;
  font-weight: 600;
  text-align: center;
  align-self: center;
  cursor: pointer;
  font-family: "Poppins";
}
.bounty_firstStep {
  background-color: #555555;
  height: 5px;
  cursor: pointer;
  width: 100%;
}
.bounty_step {
  background-color: #555555;
  height: 5px;
  width: 100%;
  cursor: pointer;
}
.bounty_step_staging {
  background-color: #f1f1f1;
  height: 10px;
  width: 100%;
  cursor: pointer;
}
.bounty_step_empty {
  background-color: #55555500;
  height: 5px;
  width: 100%;
}

.bounty_overCorrectionStepsNumber {
  background-color: red;
  height: 5px;
  width: 100%;
  cursor: pointer;
}
.bounty_passiveAligners {
  background-color: lightgrey;
  height: 5px;
  width: 100%;
  cursor: pointer;
}
.bounty_steps_content {
  display: flex;
  height: 60px;
  position: relative;
  flex: 1 1 0;
  justify-content: space-evenly;
}
.bounty_steps_total {
  display: flex;
  color: #2e2e2f;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins";
  z-index: 9;
}

.slider {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  height: 60px;
  width: 35px;
  border-radius: 0px !important;
  -webkit-appearance: none;
}
input[type="range"]::-moz-range-thumb {
  height: 60px;
  width: 35px;
  border-radius: 0px !important;
}

input[type="range"]::-ms-thumb {
  height: 60px;
  width: 35px;
  border-radius: 0px !important;
}
.bounty_step_div {
  //max-width: 10px;
  //min-width: 10px;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}
.bounty_step_div_staging {
  //min-width: 10px;
  width: 100%;
  height: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}
.bounty_step_label {
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  font-family: "Poppins";
  position: absolute;
  top: 2px;
  z-index: 20;
}
.bounty_step_label_staging {
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  font-family: "Poppins";
  position: absolute;
  top: -10px;
  z-index: 20;
}
.bounty_step_label_active {
  font-size: 22px;
  font-weight: 600;
  font-family: "Poppins";
  z-index: 20;
  width: 100%;
  position: absolute;
  top: -10px;
  // right: 25%;
  left: 0px;
  margin: 0 auto;
  position: absolute;
  text-align: center;
}

.custom-range {
  width: 100%;
  // max-width: 300px;
  position: absolute;
  margin: 0 auto;
  bottom: 0px;
  z-index: 99;
  opacity: 0;
  cursor: pointer;
}

.range-slider {
  width: 100%;
  height: 60px;
  background-color: #ccc;
  position: relative;
}

.range-thumb {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
