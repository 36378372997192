$green: #009ace;

.toggle {
  cursor: pointer;
  display: inline-block;
}
.toggle-switch {
  display: inline-block;
  background: #ccc;
  border-radius: 24px;
  width: 44px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
  &:before,
  &:after {
    content: "";
  }
  &:before {
    display: block;
    background: #fff;
    border-radius: 50%;
    // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 14px;
    height: 14px;
    position: absolute;
    top: 3px;
    left: 4px;
    transition: left 0.25s;
  }

  .toggle-checkbox:checked + & {
    background: $green;
    &:before {
      left: 25px;
    }
  }
}
.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}
.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}
