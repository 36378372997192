  $ts_primary: #00b189;
  $ts_secondary: #00b189;

@mixin scrollbars(
  $size,
  $foreground-color,
  $hover-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $hover-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

.main_container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.main_container_mobile {
  // height: 100dvh;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}



.bounty_double_bounce1,
.bounty_double_bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--ts_primary, $ts_primary);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.bounty_double_bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}



.bounty_spinner {
  display: block;
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  position: absolute;
  display: none;
}
.bounty_loading_title {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  position: absolute;
  width: 300px;
  text-align: center;
  margin-top: 50px;
  font-size: 12px;
  font-family: "Poppins";
}
.bounty_spinner_container {
  background-color: #00000017;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 99;
}
.bounty_screen_divider {
  content: "";
  border-bottom: 4px solid #2b2f2e;
  transform: rotate(90deg);
  width: 90%;
  position: absolute;
  //top: 50%;
  left: 5%;
  z-index: 2;
}
.tp_header {
  display: grid;
  grid-template-columns: 200px 1fr auto;
  background-color: #ffffff;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  margin: 0px 0.25px;
  z-index: 11;
  border: 2px #c8c8ca solid;
  position: absolute;
  top: 0;
  border: 1px solid #898a8d;
  width: 100vw;
  }
.fullscreen-enabled .tp_header {
  display: none;
}
.ts-dot {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50%;
}
.tp_details {
  font-family: "Poppins";
  color: #2e2e2f;
  font-weight: 400;
  grid-column: 2;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn_1 {
  border: 1px solid #898a8d;
  box-sizing: border-box;
  color: #898a8d;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: none;
  font-size: 16px !important;
  //width: 130px !important;
  font-family: "Poppins" !important;
  font-size: 16px !important;
  line-height: 22px !important;
  padding: 8px 16px;
  font-weight: 500;
  cursor: pointer;
}
.btn_2 {
  border: 2px solid   var(--ts_primary, $ts_primary);
  box-sizing: border-box;
  color: #fff;
  background-color:  var(--ts_primary, $ts_primary);
  border-radius: 20px;
  box-shadow: none;
  font-size: 16px !important;
  //width: 130px !important;
  font-family: "Poppins" !important;
  font-size: 16px !important;
  line-height: 22px !important;
  padding: 8px 16px;
  font-weight: 500;
  cursor: pointer;
}
.btns {
  display: flex;
  justify-content: space-between;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  grid-column: 3;
}
.main_div {
  height: 100vh;
  width: 100vw;
  grid-row: 2;
}

.fullscreen-enabled .main_div {
  background: rgb(255, 255, 255);
  height: 100vh;
}
.bounty_rotate_phone {
  display: none;
}

.bounty_rotate_phone {
  display: none;
}

.bounty_footer {
  font-family: "Poppins";
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  position: absolute;
  bottom: 70px;
  text-align: left;
  width: fit-content;
  @media (orientation: portrait) {
    width: 240px;
  }
}
.bounty_footer_landscape_mobile {
  @media (orientation: landscape) {
    bottom: calc(130px + env(safe-area-inset-bottom)) !important;
  }
}
.bounty_footer_right {
  font-family: "Poppins";
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 50%;
  position: absolute;
  bottom: 70px;
  text-align: left;
  width: fit-content;
}
.bounty_footer_left {
  font-family: "Poppins";
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  margin-left: auto;
  margin-right: auto;
  left: 50%;
  right: 0;
  position: absolute;
  bottom: 70px;
  text-align: left;
  width: fit-content;
}

.bounty_viewer_not_found {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 50%;
  width: 50%;
  text-align: center;
  display: none;
  z-index: 10;
}
.bounty_viewer_not_found_text {
  font-size: 16px;
  font-family: "Poppins";
}

.bounty_staging_modal {
  width: 30vw;
  height: calc(100vh - 160px);
  top: 80px;
  right: 32px;
  padding: 21px 14px 21px 14px;
  background-color: #fff;
  z-index: 999;
  position: absolute;
  border-top: 0.9px solid #00000033;
  flex-direction: column;
  filter: drop-shadow(
    0px 3.6137566566467285px 3.6137566566467285px 0px #00000040
  );
  // position: relative;
}
.bounty_bolton_modal {
  width: 30vw;
  height: calc(100vh - 160px);
  top: 80px;
  right: 32px;
  padding: 14px;
  background-color: #fff;
  z-index: 999;
  position: absolute;
  font-family: "Poppins";
  //border-top: 0.9px solid #00000033;
  border-radius: 7px;
  flex-direction: column;
  filter: drop-shadow(
    0px 3.6137566566467285px 3.6137566566467285px 0px #00000040
  );
  // position: relative;
}
.bounty_staging_modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
}
.bounty_staging_modal_title {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 590;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
}

.bounty_staging_modal_body {
  height: -webkit-fill-available;
  margin-top: 32px;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  padding: 0px 12px;
  height: 100%;
  @include scrollbars(5px, #313131, #2b2f2e, rgba(100, 100, 100, 0.2));
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  justify-items: center;
}
.bounty_staging_modal_body_info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
  margin-top: 24px;
  div {
    font-family: "Poppins";
    font-weight: 400;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
}
.bounty_staging_modal_body_table {
  display: grid;
  font-family: "Poppins";
  grid-template-columns: auto 1fr auto;
  justify-items: center;
  height: fit-content;
  align-items: center;
  div {
    height: fit-content;
  }
}
.bounty_staging_modal_body_main_table {
}

.bounty_staging_modal_body_content {
  display: flex;
  // height: 60px;
  position: relative;
  flex: 1 1 0;
  justify-content: space-evenly;
}

.bounty_staging_modal_number_left {
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  display: grid;
}
.bounty_staging_modal_number_right {
  grid-column: 2;
  grid-row: 1;
}

.bounty_bolton_table_4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  grid-template-rows: auto auto;
  margin-top: 16px;
}

.bounty_bolton_table_3_lr {
  display: flex;
  grid-column: 1;
  grid-row: 1;
  text-align: center;
  flex-direction: column;
}

.bounty_bolton_table_3_ll {
  display: flex;
  grid-column: 2;
  grid-row: 1;
  text-align: center;
  flex-direction: column;
}
.bounty_bolton_table_upper_total {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 0px 24px;
  font-weight: 500;
}
.bounty_bolton_table_lower_total {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 0px 24px;
  font-weight: 500;
}

.bounty-scrollbar {
  @include scrollbars(5px, #313131, #2b2f2e, rgba(100, 100, 100, 0.2));
}
.bounty_color_slider {
  background-color: white;
  width: 109px;
  height: 14px;
  padding: 6px;
  border-radius: 16px;
  position: absolute;
  top: 68px;
  left: 175px;
  display: grid !important;
  .range {
    -webkit-appearance: none;
    width: 85%;
    height: 6px;
    border-radius: 10px;
    outline: none;
    padding: 0;
    margin: 0 auto;
    position: absolute;


    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 10px;
      height: 10px;
      border: 6px !important;
      background: white;
      border-color: #2c2c2c;
      border-radius: 16px !important;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 10px;
      height: 10px;
      background: white;
      border: 6px !important;
      border-color: #2c2c2c;
      border-radius: 16px !important;
      cursor: pointer;
    }

    &::-ms-thumb {
      width: 20px;
      height: 20px;
      background: white;
      border: 6px !important;
      border-color: #2c2c2c;
      border-radius: 16px !important;
      cursor: pointer;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 6px;
      border-radius: 10px;
    }

    &::-moz-range-track {
      width: 100%;
      height: 6px;
      border-radius: 10px;
    }

    &::-ms-track {
      width: 100%;
      height: 6px;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-ms-fill-lower {
      border-radius: 10px;
    }

    &::-ms-fill-upper {
      border-radius: 10px;
    }
  }
}





