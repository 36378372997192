.customizer {
  background: #fff;
  position: fixed;
  height: 100%;
  width: 280px;
  top: 0px;
  right: -280px;
  z-index: 100;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  transition: 0.3s ease-in;
}

.customizer .translation_toggle {
  background: #1c64f2 !important;
  top: 40%;
  max-width: 54px;
}

.customizer .service-panel-toggle {
  background: #fc4b6c;
  padding: 12px 20px;
  color: #fff;
  position: absolute;
  top: 30%;
  left: -57px;
  cursor: pointer;
  svg {
    width: 1em;
    height: 1em;
    font-size: large;
    font-weight: bold;
    path {
      fill: #ffffff;
    }
  }
}

.svg_icon {
  path,
  circle {
    stroke: #000000;
  }
}
.svg_email_icon {
  path,
  circle {
    fill: #000000;
  }
}

.translate_icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -moz-user-select: none;
}
.svg_icon_traslation_secondary {
  width: 1em;
  height: 1em;
  display: inline-block;
  path {
    fill: white;
  }
  color: white;
  font-size: 18px !important;
}

/*******************
customizer
*******************/
.customizer {
  background: #ffffff;
  position: fixed;
  height: 100%;
  width: 280px;
  top: 0px;
  right: -280px;
  z-index: 102;
  // box-shadow: ;
  transition: 0.3s ease-in;
  .service-panel-toggle {
    background: #ff0000;
    padding: 12px 20px;
    color: #ff0000;
    position: absolute;
    top: 30%;
    left: -57px;
    cursor: pointer;
  }
  .translation_toggle {
    background: #1c64f2 !important;
    top: 40%;
    // left: -63px;
    max-width: 54px;
    padding: 12px 20px;
  }
  &.show-service-panel {
    right: 0px;
  }
  .customizer-body {
    position: relative;
    height: 100%;
  }
  .customizer-tab {
    display: flex;
    .nav-item {
      width: 33.33%;
      text-align: center;
      .nav-link {
        padding: 15px 12px;
        color: black;
        border-bottom: 3px solid transparent;
      }
    }
  }
}
.customizer {
  right: -280px;
  left: auto;
  &.show-service-panel {
    right: 0;
    left: auto;
  }
  .service-panel-toggle {
    left: -54px;
    right: auto;
    height: 45px;
    width: 54px;
  }
}
.svg_icon_traslation_secondary2 {
  color: #000000;
  font-size: 24px !important;
}
.svg_icon_traslation_secondary {
  color: var(--secondary);
  font-size: 18px !important;
  // path , circle{
  //   stroke: var(--primary);
  //   fill: var(--primary);
  // }
}
.edit_icon_translation {
  position: absolute;
  right: 45px;
  z-index: 101;
  top: 2.5%;
  //   bottom: 50%;
  cursor: pointer;

  svg {
    color: #1c64f2;
    font-size: 24px !important;
    height: 24px;
    width: 24px;
    path {
      fill: #1c64f2;
    }
  }
}
.missing_translation {
  color: #f44336 !important;

  textarea {
    color: #f44336 !important;
  }
}
.bounty_canvas_container {
  .edit_icon_translation {

    top:8.5% !important;
  }
}
.translation-groups {
  .edit_icon_translation {
    top: 20% !important;
  }
}
.controls {
  .edit_icon_translation {
    right: 52px !important;
    top: 5% !important;
  }
}
